import React from 'react';

import { FaCode, FaFire, FaGithub, FaGlobe, FaLightbulb, FaNpm, FaRocket } from 'react-icons/fa';

interface CommandItem {
  command: string;
  output: JSX.Element | string;
}

export const getOutput = (command: string, setCommand: React.Dispatch<React.SetStateAction<CommandItem[]>>): JSX.Element | string => {
  switch (command.toLowerCase()) {
    case 'sudo rm -rf /*':
      const closeWindow = () => {
        const newWindow = window.open('about:blank', '_blank');
        if (newWindow) {
          newWindow.close();
        }

        window.close();
        window.location.href = 'about:blank';
        window.history.back();
      };

      closeWindow();
      return <></>;
    case 'help':
      return (
        <div className="flex flex-col space-y-1">
          <span className="text-[#58a6ff]">Available commands:</span>
          {[
            { cmd: 'about', desc: 'Learn more about me' },
            { cmd: 'socials', desc: 'Find me on the web' },
            { cmd: 'skills', desc: 'Check out my technical skills' },
            { cmd: 'projects', desc: 'View some of my cool projects' },
            { cmd: 'resume', desc: 'Take a look at my resume' },
            { cmd: 'experience', desc: 'Explore my professional journey' },
            { cmd: 'position of responsibility', desc: 'Explore my psuedo professional journey' },
            { cmd: 'goals', desc: 'Discover my aspirations' },
            { cmd: 'clear', desc: 'Clear the terminal' },
            { cmd: 'sudo rm -rf /*', desc: 'Try at your own risk!' },
          ].map(({ cmd, desc }) => (
            <div key={cmd} className="flex">
              <span className="text-[#f0883e]">{cmd}</span>
              <span className="text-[#8b949e]">- {desc}</span>
            </div>
          ))}
        </div>
      );
    case 'about':
      return (
        <div className="space-y-2">
          <p>Hey there! Glad you’re here—this might just be the start of something awesome (no pressure).</p>  

<p>I’m <span className="text-[#f0883e]">Prayas Yadav</span>, a tech enthusiast juggling <span className="text-[#f0883e]">web development</span>, <span className="text-[#f0883e]">DevOps</span>, and a low-key obsession with startup ideas. I’m the kind of person who can debug an error at 2 AM, merge pull conflicts like it’s a boss fight, and still have enough energy to pitch you a startup idea that may or may not involve chai delivery drones.</p>  

<p>Web development? Sure, everyone does it. But my superpower? Making websites that are not just functional but also a little too addictive to leave (thank me later for the extra screen time). In DevOps, I like to automate things to the point where it feels like the systems are running themselves. My tagline? <span className="text-[#f0883e]">“Code it, break it, fix it, repeat.”</span></p>  

<p><span className="text-[#f0883e]">Fun fact:</span> I thrive on chaos—be it solving a bug that just won’t quit or brainstorming startup ideas that are borderline genius (or so I tell myself). And yes, I believe Git conflicts are like life: messy but solvable.</p>  

<p>So, whether it’s building the next big thing, geeking out over DevOps pipelines, or just sharing startup banter, let’s connect. After all, great things start with small pull requests.</p>
        </div>
      );
    case 'socials':
      return (
        <div className="space-y-2">
          <p>Social life? It's in a constant state of git commit -m "Working on it". When I’m not deploying code or managing pipelines, I’m probably forking memes. But hey, here’s where you can clone me online: :) ...</p>
          <p>Thanks for your interest though! Here's where you can find me online:</p>
          <div className="flex flex-col space-y-1">
            <div className="flex">
              <span className="text-[#f0883e] w-24">GitHub</span>
              <a href="https://github.com/Prayas248" target="_blank" rel="noopener noreferrer" className="text-[#58a6ff]">
                Prayas248
              </a>
            </div>
            <div className="flex">
              <span className="text-[#f0883e] w-24">LinkedIn</span>
              <a href="https://www.linkedin.com/in/prayasyadav248/" target="_blank" rel="noopener noreferrer" className="text-[#58a6ff]">
                Prayas Yadav
              </a>
            </div>
            <div className="flex">
              <span className="text-[#f0883e] w-24">Email</span>
              <a href="mailto:prayasyadav248@gmail.com" className="text-[#58a6ff]">
                prayasyadav248@gmail.com
              </a>
            </div>
          </div>
          <p>Feel free to reach out! I'm most responsive on LinkedIn.</p>
        </div>
      );
    case 'skills':
      return (
          <div className="space-y-2">
            <div>
              <span className="text-[#f0883e]">Programming Languages:</span>
              <p className="text-[#8b949e]">C++, C, Python, JavaScript, TypeScript, HTML, CSS</p>
            </div>
            <div>
              <span className="text-[#f0883e]">Frameworks/Libraries:</span>
              <p className="text-[#8b949e]">React.js ,Nextjs , Node.js, Express.js, Tailwind CSS</p>
            </div>
            <div>
              <span className="text-[#f0883e]">Databases:</span>
              <p className="text-[#8b949e]">MongoDB, MySQL, PostgreSQL, Firestore</p>
            </div>
            <div>
              <span className="text-[#f0883e]">Query Languages:</span>
              <p className="text-[#8b949e]">GraphQL, SQL</p>
            </div>
            <div>
              <span className="text-[#f0883e]">Cloud Services:</span>
              <p className="text-[#8b949e]">AWS, Azure, Firebase</p>
            </div>
            <div>
              <span className="text-[#f0883e]">Tools:</span>
              <p className="text-[#8b949e]">Docker, Kubernetes, Jenkins</p>
            </div>
            <div>
              <span className="text-[#f0883e]">Version Control:</span>
              <p className="text-[#8b949e]">Git, GitHub</p>
            </div>
          </div>

          );
          case 'projects':
          return (
          <div className="space-y-4">
            {[
              {
                name: 'Dobby',
                desc: 'A comprehensive platform offering an Online IDE, Video Conferencing, Chat Application, and Collaborative Whiteboard for seamless real-time coding, communication, and collaboration.',
                github: 'https://github.com/Prayas248/Two-Code-Combined',
              },
              {
                name: 'Jijivisha Web Application',
                desc: 'Developed a full-stack e-commerce web application for Jijivisha, a company, to streamline online shopping and enhance user experience.',
                github: 'https://github.com/Prayas248/Jijivisha_web_application',
                link:"https://jijivisha-personal.netlify.app/",
              },
              {
                name: 'Streaming Application',
                desc: 'Developed a full-stack streaming application with features such as video upload and management, seamless video playback, advanced search functionality, personalized recommendations, user subscriptions, and comment sections for engagement.',
                github: 'https://github.com/Prayas248/Streaming-Application',
              },
              {
                name: 'Movies Recommendor',
                desc: 'Developed a movie recommendation application that provides personalized movie suggestions, enables users to search for movies by title or genre, and offers the option to download selected movies for offline viewing.',
                github: 'https://github.com/Prayas248/Movies-Recommendor-2',
              },
              {
                name: 'Chat App',
                desc: 'Developed a group chat application allowing users to join and participate in conversations anonymously, with real-time messaging and seamless group interactions.',
                github: 'https://github.com/Prayas248/Chat_App',
              },
            ].map((project, index) => (
              <div key={index} className="border border-[#30363d] rounded-md p-4 bg-[#0d1117]">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-[#f0883e] text-lg font-semibold">{project.name}</h3>
                    <p className="text-[#8b949e] mt-1">{project.desc}</p>
                  </div>
                  <div className="flex space-x-2">
                    {project.github && (
                      <a
                        href={project.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#58a6ff] hover:text-[#58a6ff] transition-colors"
                      >
                        <FaGithub size={24} />
                      </a>
                    )}
                    {project.link && (
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#58a6ff] hover:text-[#58a6ff] transition-colors"
                      >
                        <FaGlobe size={24} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          );
          case 'clear':
          setCommand([]);
          return '';
          case 'resume':
          window.open('https://drive.google.com/file/d/12mLy33OyMKJVHFsm8xT_P_f6qQExfpKB/view?usp=drivesdk', '_blank');
          return 'Opening resume in a new tab...';
          case 'experience':
          return (
          <div className="space-y-6">
            {[
              {
                company: 'Ogive Technologies',
                role: 'Full Stack Developer',
                location: 'Remote, India',
                date: 'May 2024 - June 2024',
                description: 'Worked on development of web application using MERN stack',
                achievements: [
                  'Collaborated with a team of 2 developers and project/product managers, achieving a 30% reduction in development time and improving product features based on user feedback.',
                  'Directed the development of a scalable e-commerce platform for Jijivisha, incorporating best practices and responsive design; attracted 30% more organic traffic within the first month of testing.',
                  'Engineered and integrated secure user authentication using JWT, ensuring data integrity and protecting user sessions with industry-standard encryption techniques.',
                  'Created a customizable admin panel that enhanced product management capabilities, enabling real-time updates and integrations, now supporting over 10 distinct product lines and increasing overall team productivity by 25%.',
                ],
                icon: <FaCode className="text-[#4CAF50] text-4xl" />,
              },
            ].map((job, index) => (
              <div
                key={index}
                className="relative bg-gradient-to-r from-[#1a1b26] to-[#24283b] rounded-lg p-6 shadow-lg border border-[#30363d] overflow-hidden"
              >
                <div className="absolute top-0 right-0 w-24 h-24 bg-gradient-to-br from-[#f0883e] to-[#ff4d4d] opacity-10 rounded-bl-full"></div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">{job.icon}</div>
                  <div className="flex-grow">
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="text-[#f0883e] text-xl font-bold">{job.company}</h3>
                      <span className="text-[#8b949e] text-sm">{job.date}</span>
                    </div>
                    <p className="text-[#58a6ff] font-semibold mb-1">{job.role}</p>
                    <p className="text-[#8b949e] text-sm mb-2">{job.location}</p>
                    <p className="text-[#8b949e] mb-4">{job.description}</p>
                    <div className="space-y-2">
                      <p className="text-[#f0883e] font-semibold">🔥 Key Achievements:</p>
                      <ul className="list-none space-y-2">
                        {job.achievements.map((achievement, i) => (
                          <li key={i} className="flex items-start">
                            <span className="text-[#f0883e] mr-2">▹</span>
                            <span className="text-[#8b949e]">{achievement}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#f0883e] to-[#ff4d4d]"></div>
              </div>
            ))}
          </div>
          );
          case 'position of responsibility':
          return (
          <div className="space-y-6">
            {[
              {
                company: 'Webverse',
                role: 'Club Coordinator',
                date: 'January 2024 - Present',
                description: 'Web Development Club of College, fostering a community of web enthusiasts and facilitating skill development.',
                achievements: [
                  'Spearheaded activities for the college’s web development club, organizing events to promote practical learning.',
                  'Delivered training sessions and workshops, empowering over 300 students with practical web development skills and industry insights.',
                ],
                icon: <FaCode className="text-[#007BFF] text-4xl" />,
              },
              {
                company: 'OPCODE',
                role: 'Project Manager',
                date: 'March 2024 - April 2024',
                description: 'Open-Source Intra-College Event promoting student contributions to open source.',
                achievements: [
                  'Spearheaded OPCODE, overseeing a portfolio of over 10 web development projects deliberately seeded with errors.',
                  'Directed project life cycles from conception to execution, fostering a collaborative environment where students resolved errors via GitHub pull requests.',
                  'Enhanced participants’ skills in open-source contributions while promoting teamwork and problem-solving.',
                ],
                icon: <FaCode className="text-[#FF5722] text-4xl" />,
              },
              
            ].map((job, index) => (
              <div
                key={index}
                className="relative bg-gradient-to-r from-[#1a1b26] to-[#24283b] rounded-lg p-6 shadow-lg border border-[#30363d] overflow-hidden"
              >
                <div className="absolute top-0 right-0 w-24 h-24 bg-gradient-to-br from-[#f0883e] to-[#ff4d4d] opacity-10 rounded-bl-full"></div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">{job.icon}</div>
                  <div className="flex-grow">
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="text-[#f0883e] text-xl font-bold">{job.company}</h3>
                      <span className="text-[#8b949e] text-sm">{job.date}</span>
                    </div>
                    <p className="text-[#58a6ff] font-semibold mb-1">{job.role}</p>
                    <p className="text-[#8b949e] mb-4">{job.description}</p>
                    <div className="space-y-2">
                      <p className="text-[#f0883e] font-semibold">🔥 Key Achievements:</p>
                      <ul className="list-none space-y-2">
                        {job.achievements.map((achievement, i) => (
                          <li key={i} className="flex items-start">
                            <span className="text-[#f0883e] mr-2">▹</span>
                            <span className="text-[#8b949e]">{achievement}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#f0883e] to-[#ff4d4d]"></div>
              </div>
            ))}
          </div>
          );
          case 'goals':
          return (
          <div className="space-y-2">
            <p>
              The future is full of possibilities, and I'm excited to explore them! While I'm not entirely sure where life will take me, I have some
              guiding principles and areas of interest:
            </p>
            <ul className="list-disc list-inside text-[#8b949e]">
              <li>
                Pursue projects and roles that bring <span className="text-[#f0883e]">happiness</span> and fulfillment
              </li>
              <li>
                Be part of <span className="text-[#f0883e]">impactful initiatives</span> that can make a difference in the world
              </li>
              <li>
                Explore cutting-edge fields like <span className="text-[#f0883e]">Artificial Intelligence</span>,{' '}
                <span className="text-[#f0883e]">Space Technology</span>, <span className="text-[#f0883e]">Nuclear Energy</span>, and even{' '}
                <span className="text-[#f0883e]">Hydroponics</span>
              </li>
            </ul>
            <p>I'm open to wherever my passion and opportunities lead me. After all, that's the beauty of life - it's full of surprises!</p>
          </div>
          );
          default:
          return (
          <div>
            <span className="text-[#f97583]">Command not found.</span>
            <br />
            Type <span className="text-[#f0883e]">help</span> to see available commands.
          </div>
          );
  }
};
